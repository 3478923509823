import {PageProps, graphql, useStaticQuery} from 'gatsby'
import React, {useEffect, useState} from 'react'
import {animated as a, useSpring} from 'react-spring'

import Cart from '../components/gallery/cart/Cart'
import Layout from '../components/ui/layout'
import LogoGatsby from '../images/svgs/tech/gatsby.svg'
import LogoGit from '../images/svgs/social/github.svg'
import LogoInsta from '../images/svgs/social/insta.svg'
import LogoMail from '../images/svgs/social/mail.svg'
import LogoNode from '../images/svgs/tech/nodejs.svg'
import LogoOf from '../images/svgs/tech/openFrameworks.svg'
import LogoProcessing from '../images/svgs/tech/processing.svg'
import LogoPy from '../images/svgs/tech/python.svg'
import LogoReact from '../images/svgs/tech/React-icon.svg'
import LogoStyled from '../images/svgs/tech/styled-components.svg'
import LogoTailwind from '../images/svgs/tech/tailwindcss.svg'
import SEO from '../components/shared/seo'
import ghSvg from '../images/gh.svg'
import mailSvg from '../images/mail.svg'
import styled from 'styled-components'
import {type} from 'os'
import {useLayoutValue} from '../components/ui/layoutProvider'

// animated react spring div
const Divider = styled(a.div)`
  position: relative;
  /* width: 100%; */
  border-bottom: 1px;
  border-color: white;
  border-style: solid;
  will-change: width, opacity;
`

const About: React.FC<PageProps<any>> = ({props}) => {
  const [loading, set] = useState(false)
  const {width, opacity} = useSpring({
    width: `${loading ? 100 : 0}%`,
    opacity: loading ? 0 : 1,
    config: {mass: 2, tension: 200, friction: 80},
  })
  useEffect(() => {
    set(true)
  }, [])

  return (
    <>
      <SEO
        title="About | ajdore.xyz"
        keywords={[`Computational Art`, `T1`, `John Dore`, `Plotter Drawings`]}
      />
      <main className="flex flex-col">
        <div className="text-2xl text-white  w-full  bg-gray tracking-widest">
          <div className="mx-8 md:mx-10  text-white text-5xl md:text-7xl pt-20 leading-header font-normal ">
            about
          </div>
        </div>
        <div className="md:p-0 text-xs md:w-1/2 w-full md:m-10 p-8 mb-10">
          <div className=" text-left  text-base md:text-xl">
            I'm a full-stack engineer, currently based in London.
          </div>
          <div className=" leading-header text-white underline text-2xl mt-8 mb-6 ">drop me a message</div>
          {/* <Divider
            className="mb-4"
            style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider> */}
          <div className="flex  flex-row flex-wrap items-center my-2 ">
            <a
              className="mr-4 h-8 w-12 "
              href="https://www.instagram.com/john_dore_/"
              target="_blank">
              <LogoInsta className="w-12 h-8" />
            </a>
            <a className="mr-4 h-8 w-12 " href="https://github.com/johndore" target="_blank">
              <LogoGit className="w-12 h-8" />
            </a>
            <a className="mr-4 h-8 w-12 " href="mailto:info@johndore.xyz" target="_blank">
              <LogoMail className="w-12 h-8" />
            </a>
          </div>
          {/* <Divider
            className="my-4 "
            style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider> */}
          <div className=" leading-header text-white text-2xl mt-8 ">things I'm using...</div>
          <Divider
            className="mb-4"
            style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider>
          <div className="flex  flex-row flex-wrap items-center my-2 ">
            <a className="mr-4 h-8 w-12 " href="https://www.nodesjs.com" target="_blank">
              <LogoNode className="w-12 h-8" />
            </a>
            <a className="mr-4 h-8 w-12 " href="https://www.reactjs.com" target="_blank">
              <LogoReact className="w-12 h-8" />
            </a>

            <a className="mr-4 h-6" href="https://www.gatsbyjs.com" target="_blank">
              <LogoGatsby className="h-6" />
            </a>

            <a className="mr-4 h-4" href="https://www.tailwindcss.com" target="_blank">
              <LogoTailwind className="h-4" />
            </a>
            <a
              className="mr-6 h-10 items-center"
              href="https://www.styledcomponents.com"
              target="_blank">
              <LogoStyled className="h-10" />
            </a>
            {/* <div className="h-4 mr-4 border border-white"></div> */}
            <a className="h-10 mr-4" href="https://www.processing.org" target="_blank">
              <LogoProcessing className="h-10" />
            </a>
            <a className="mr-10 h-6 w-6" href="https://openframeworks.cc/" target="_blank">
              <LogoOf className="h-6 w-auto" />
            </a>
            <a className="h-10 mr-6" href="https://www.python.org/" target="_blank">
              <LogoPy className="h-10 w-auto " />
            </a>
          </div>

          <Divider
            className="my-4 "
            style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider>

          <div className=" leading-header  text-white text-2xl mt-8 ">education</div>
          <Divider
            className="mb-2 "
            style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider>
          <div className="py-2 text-jet  text-sm  md:flex md:justify-between">
            <div className="">MA Computational Arts @ Goldsmiths University</div>
            <div className="font-mono text-left mt-1 ">[2017 – 2019]</div>
          </div>
          <div className="py-2  text-jet  text-sm  md:flex md:justify-between">
            <div className="">BA with Honors in Film Studies @ Edge Hill University</div>
            <div className="font-mono mt-1">[2013 - 2016]</div>
          </div>
          <div className="py-2  text-jet text-sm md:flex md:justify-between">
            <div className="">Foundation Diploma in Art & Design @ Falmouth University</div>
            <div className="font-mono mt-1">[2010 - 2011]</div>
          </div>
          <Divider
            className="my-2"
            style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider>
          {/* <div className="pt-16 pb-4 text-2xl tracking-widest text-jet">get in touch</div> */}
        </div>
      </main>
    </>
  )
}

export default About
